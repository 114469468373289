<div
	class="flex flex-col justify-between min-h-screen"
	[ngClass]="{ 'no-scoll': scrollDisabled }"
>
	<div>
		@if (showHeaderFooter) {
			<uc-navigation
				ngSkipHydration
				(toggleFixed)="scrollDisabled = $event"
				data-cy="navigation"
			/>
		}
		<router-outlet></router-outlet>
	</div>
	@if (showHeaderFooter && showFooter) {
		<uc-footer #footer />
	}
</div>
